import PropTypes from "prop-types";
import React from "react";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/public/createUploadLink";
import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: createUploadLink({
      uri: process.env.GATSBY_STAPI,
    }),
  });

  return (
    <ApolloProvider client={client}>
      <div className="flex flex-col min-h-screen font-sans text-gray-900">
        <Header />

        {children}

        <Footer />
      </div>
    </ApolloProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
