import React from "react";
import Link from "gatsby-link";

import vinylWhite from "../images/vinyl-record-svgrepo-com.svg";

function Header() {
  return (
    <nav className="bg-primary-light">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <nav
          className="relative flex items-center justify-between h-20 md:justify-center"
          aria-label="Global"
        >
          <div
            className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0"
            style={{ minWidth: 30, marginRight: 16, marginLeft: 8 }}
          >
            <div className="flex items-center justify-between w-full lg:w-auto">
              <Link to="/">
                <span className="sr-only">Serial Records</span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src={vinylWhite}
                  alt="Serial Records Logo"
                />
              </Link>
            </div>
          </div>
          <div className="flex lg:space-x-10">
            <div className="flex inline-block text-left">
              <div className="border-transparent text-white hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                <Link to="/kategorien-illustriert?letter=a">
                  Kategorien (illustriert)
                </Link>
              </div>
            </div>
            <div className="flex inline-block text-left">
              <div className="border-transparent text-white hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                <Link to="/kategorien-alphabetisch?letter=a">
                  Kategorien (alphabetisch)
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </nav>
  );
}

export default Header;
